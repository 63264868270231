<template>
  <footer class="bg-cblack" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="flex flex-col sm:grid gap-y-12 sm:grid-cols-3 lg:grid-cols-4">
        <ThePublicFooterLinkCol :title="$t(`vehicle`, 2)">
          <li v-for="category in categories" :key="category.slug">
            <NuxtLink
              :to="`${funnelRoute}?category=${category.slug}`"
              class="text-base font-normal leading-6 text-gray-300 hover:underline"
            >
              {{ category.title }}
            </NuxtLink>
          </li>
        </ThePublicFooterLinkCol>

        <ThePublicFooterLinkCol :title="$t(`theFooter.company`)">
          <li v-for="item in navigation.company" :key="item.name">
            <NuxtLink
              :to="item.href"
              :target="item.target ? item.target : '_self'"
              class="text-base font-normal leading-6 text-gray-300 hover:underline"
            >
              {{ $t(item.name) }}
            </NuxtLink>
          </li>
        </ThePublicFooterLinkCol>

        <ThePublicFooterLinkCol :title="$t(`theFooter.legal`)">
          <li v-for="item in navigation.legal" :key="item.name">
            <NuxtLink
              :to="item.href"
              class="text-base font-normal leading-6 text-gray-300 hover:underline"
            >
              {{ $t(`theFooter.${item.name}`) }}
            </NuxtLink>
          </li>
        </ThePublicFooterLinkCol>

        <div
          class="col-span-2 row-start-3 text-left lg:col-span-1 md:col-span-3 md:row-start-auto"
        >
          <h3
            class="text-sm font-semibold tracking-wider text-gray-200 uppercase"
          >
            {{ $t(`theFooter.name`) }}
          </h3>

          <div
            class="mt-4 space-y-4 text-base font-semibold leading-6 text-gray-300"
          >
            <p>{{ $t(`theFooter.address`) }}</p>

            <PhoneButton
              is-text-displayed-mobile
              class="max-w-68 lg:max-w-full"
            />

            <!-- <WhatsAppButton :size="Size.sm" class="max-w-68 lg:max-w-full" /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="p-4 m-auto sm:p-6 lg:px-8 max-w-7xl">
      <div
        class="py-8 text-gray-300 border-t border-gray-700 md:flex md:items-center md:justify-between"
      >
        <div class="flex space-x-6 md:order-2">
          <a
            v-for="item in navigation.socialItems"
            :key="item.name"
            target="_blank"
            :href="item.href"
            class="hover:text-gray-200"
          >
            <span class="sr-only">{{ item.name }}</span>
            <component :is="item.icon" class="w-5 h-5" aria-hidden="true" />
          </a>
        </div>
        <div
          class="flex items-center content-center mt-8 space-x-2 text-white md:mt-0"
        >
          <ColoniaIcon class="w-6 h-6" />
          <p class="text-base font-normal text-gray-300 md:mt-0">
            {{ $t('theFooter.copyright') }}
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import LinkedInIcon from './icons/LinkedInIcon.vue'
import FacebookIcon from './icons/FacebookIcon.vue'
import InstagramIcon from './icons/InstagramIcon.vue'
import ColoniaIcon from './icons/ColoniaIcon.vue'
import { useSortedCategories } from '@/composables/useSortedCategories'

const funnelRoute = await useFunnelRoute()
const categories = useSortedCategories()

const navigation = {
  company: [
    {
      name: 'about',
      href: '/ueber-uns',
    },
    {
      name: 'contact',
      href: '/kontakt',
    },
    {
      name: 'blog',
      href: '/blog',
      target: '_self',
    },
    {
      name: 'career',
      href: 'https://colonia-technologies-gmbh.jobs.personio.de/',
      target: '_blank',
    },
  ],
  legal: [
    { name: 'imprint', href: '/impressum' },
    { name: 'privacy', href: '/datenschutz' },
    { name: 'termsLessor', href: '/AGB/vermieter' },
    { name: 'termsTenant', href: '/AGB/mieter' },
  ],
  socialItems: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/people/Colonia-Tech/100088251050247/',
      icon: FacebookIcon,
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/colonia.tech/',
      icon: InstagramIcon,
    },
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/colonia-technologies/',
      icon: LinkedInIcon,
    },
  ],
}
</script>
