<template>
  <div class="w-[60%] md:w-[80%] xl:w-[85.71%]">
    <div class="flex">
      <div class="hidden mx-auto xl:ml-6 xl:flex xl:w-xl">
        <div v-for="link in links" :key="link.title" class="group">
          <div class="pt-2">
            <NuxtLink
              :to="link.path"
              :target="link.target"
              class="p-2 hover:underline hover:decoration-1 hover:underline-offset-12 hover:decoration-cred"
            >
              {{ link.title }}
            </NuxtLink>
          </div>
          <div
            v-if="link.items"
            class="absolute invisible pt-6 group-hover:visible"
          >
            <div class="p-2 text-left bg-cblack rounded-2xl">
              <div
                v-for="item in link.items"
                :key="item.title"
                class="w-full rounded-2xl bg-cblack team"
              >
                <NuxtLink
                  :to="item.path"
                  :target="item.target"
                  class="flex w-full gap-4 p-2 group2 hover:underline hover:decoration-1 hover:underline-offset-4 hover:decoration-cred"
                >
                  <div class="flex flex-1 gap-2">
                    <component
                      :is="item.icon"
                      v-if="item.icon"
                      class="w-10 h-5"
                    />
                    {{ item.title }}
                  </div>
                  <ChevronRightIcon class="h-6.5 text-cred" />
                </NuxtLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-end flex-1">
        <PublicNavigationShoppingCartButton
          v-if="isShppingCartEnabled"
          class="mr-2"
          :basket-items-count="basketItemsQuantity"
          @basket-click="onClickShoppingCart()"
        />

        <PhoneButton class="md:mr-2 bg-cblack" />

        <div class="hidden md:block">
          <VButton
            :to="'/plattform/requests'"
            class="px-5"
            :size="Size.sm"
            :variant="Variant.primary"
          >
            <div class="flex items-center justify-center space-x-2">
              <span>{{ $t('toThePortal') }}</span>
              <ArrowRightIcon class="w-4 h-4" />
            </div>
          </VButton>
        </div>
        <div class="flex items-center -mr-2 xl:hidden">
          <!-- Mobile Menu button -->

          <Menu
            as="div"
            class="relative inline-block mx-2 text-left rounded-md xl:hidden active:opacity-99 opacity-99 bg-cblack"
          >
            <div>
              <MenuButton
                class="inline-flex justify-center w-full p-1 text-sm font-medium text-white border-2 border-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-cblack"
              >
                <MenuIcon class="w-6 h-6 text-white" aria-hidden="true" />
                <span class="hidden">Menu</span>
              </MenuButton>
            </div>

            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems
                class="absolute right-0 w-56 mt-2 origin-top-right rounded-md shadow-xl bg-cblack ring-1 ring-cblack focus:outline-none"
              >
                <div class="py-1">
                  <MenuItem
                    v-for="link in smallWidthLinks"
                    :key="link.title"
                    as="div"
                    class="items-center block w-full px-4 py-2 pt-1 pl-4 font-medium text-left text-gray-300 border-gray-100 text-md border-b-1 hover:text-gray-700"
                  >
                    <NuxtLink :to="link.path" :target="link.target">
                      {{ link.title }}
                    </NuxtLink>
                  </MenuItem>
                  <MenuItem class="block m-4 md:hidden" as="div">
                    <VButton
                      class="flex items-center justify-center w-full space-x-2"
                      :to="'/plattform/requests'"
                      :size="Size.sm"
                      :variant="Variant.primary"
                    >
                      <span>{{ $t('toThePortal') }}</span>
                      <ArrowRightIcon class="w-4 h-4" />
                    </VButton>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { useI18n } from 'vue-i18n'
import { Size, Variant } from '@/types/enums'
import MenuIcon from './icons/MenuIcon.vue'
import ArrowRightIcon from './icons/ArrowRightIcon.vue'
import ChevronRightIcon from './icons/ChevronRightIcon.vue'

type LinkItem = {
  icon?: any
  path: string
  target: string
  title: string
}

const emits = defineEmits(['shopping-slider'])

const { t } = useI18n()
const { fetchStoredVehicles } = useVehicleRequestStorage()
const basketItemsQuantity = ref<number>((await fetchStoredVehicles()).size)

const funnelVariant = await useExperiment('funnel')
const isShppingCartEnabled = funnelVariant === 'v2'

const funnelRoute = await useFunnelRoute()
const categories = useSortedCategories().map((category) => {
  return {
    title: category.title,
    path: `${funnelRoute}?category=${category.slug}`,
    icon: category.icon,
    target: '_self',
  }
})

const smallWidthLinks = computed(() => {
  return [
    ...categories,
    {
      title: t('about'),
      path: '/ueber-uns',
      target: '_self',
    },
    {
      title: t('contact'),
      path: '/kontakt',
      target: '_self',
    },
    {
      title: t('blog'),
      path: '/blog',
      target: '_self',
    },
    {
      title: t('career'),
      path: 'https://colonia-technologies-gmbh.jobs.personio.de/',
      target: '_blank',
    },
    {
      title: t('rentOut'),
      path: '/vermieten',
      target: '_self',
    },
  ]
})

const links = computed(() => {
  return [
    {
      title: t('rentIn', 2),
      items: categories as LinkItem[],
    },
    {
      title: t('rentOut'),
      path: '/vermieten',
      target: '_self',
    },
    {
      title: t('theFooter.company'),
      items: [
        {
          title: t('career'),
          path: 'https://colonia-technologies-gmbh.jobs.personio.de/',
          target: '_blank',
        },
        {
          title: t('about'),
          path: '/ueber-uns',
          target: '_self',
        },
        {
          title: t('blog'),
          path: '/blog',
          target: '_self',
        },
      ] as LinkItem[],
    },
    {
      title: t('contact'),
      path: '/kontakt',
      target: '_self',
    },
  ]
})

function onClickShoppingCart() {
  useNuxtApp().$trackEvent('shopping_cart.opened', {
    count: basketItemsQuantity.value,
  })
  emits('shopping-slider')
}
</script>
